<template>
  <v-dialog v-model="dialog" scrollable max-width="600px">
    <v-card>
      <v-carousel
          :continuous="false"
          :show-arrows="true"
          hide-delimiter-background
          hide-delimiters
          v-model="carousel"
          >
          <v-carousel-item>
              <!-- <img src="@/assets/welcome-slides/1.png"/> -->

              <v-card-text>
                <div class="title">Add steps to make awesome!</div>
                <p>You can add steps when playing or manually by netering the time you want.</p>
              </v-card-text>

          </v-carousel-item>
          <v-carousel-item>
              <!-- <img src="@/assets/welcome-slides/2.png"/> -->
              <v-card-text>
                <div class="title">Build your Youtorial</div>
                <p>Steps added will apear in the side bar, or below on mobile.</p>
              </v-card-text>
          </v-carousel-item>
          <v-carousel-item>

              <!-- <img src="@/assets/welcome-slides/3.png" /> -->
              <v-card-text>
                <div class="title">Choose how each step works</div>
                <p>View or change the time to re-order the step, and toggle the step to pause or not when it's reached in the video.</p>
              </v-card-text>
          </v-carousel-item>
          <v-carousel-item>

              <!-- <img src="@/assets/welcome-slides/4.png" /> -->
              <v-card-text>
                <div class="title">Fill it with extra information.</div>
                <p>Format any additional text you want to show including: links, photo URLS, or even embeds from Giphy or other sites</p>
              </v-card-text>
          </v-carousel-item>
          </v-carousel>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="">
              <v-btn v-for="n in 4" :key="n" icon @click="carousel = n-1">
                <v-icon v-if="carousel+1 == n">radio_button_checked</v-icon>
                <v-icon v-else>radio_button_unchecked</v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="primary" large  @click="setModalCookie">Got it!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import {db} from "@/firebase"
import { mapGetters } from 'vuex'
export default {
  name: "welcomeWatchModal",
  computed:{
    ...mapGetters({
      userId:'GET_USER_ID',
    }),
  },
  data(){
    return{
      dialog: true,
      carousel:0,
    }
  },
  methods:{
    setModalCookie(){
      let vThis = this;
      let dbRef = db.collection('users').doc(this.userId);
      dbRef.update({'showWatchTour':false}).then(()=>{
        vThis.dialog = false;
      });
    }
  }
};
</script>

<style lang="scss">
  #nav{
    width: 100%;
    z-index: 9999;
    border-bottom: 1px solid #e6e6e6;
    .v-toolbar__items{
      .v-btn{height: 44px!important;}
    }
    .logo{
      max-width:150px;
    }
  }
</style>
